.verificationPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    column-gap: 2rem;
    height: 100vh;
    margin-top: 3rem;
}
.verificationPage>button{
    padding: 5px;
    border-color: white;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.verificationPage>p{
    padding-top: 3rem;
}
