.header {
    width: 100vw;
    height: 10vh;
    /* background-color: rgb(255, 98, 0); */
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-image: linear-gradient(to top right,
            rgb(159, 116, 94), rgb(55, 49, 16));

}

.link{
    color: rgb(187, 205, 50);
    font-weight: 700;
    text-decoration:double;
    font-size:1.4rem;
}
.logoutbtn{
    display: flex;
    flex-direction: column;
}
.logoutmainbtn{
    position:absolute;
    top: 3rem;
    height: 4rem;
    background-color: rgb(187, 205, 50);
    color:white;
    border-radius: 10px;
    border-color: wheat;
}

.body {
    display: flex;
    flex-direction: column;
}

.header>h3 {
    font-family: cursive;
    color: white;

    /* position: absolute; */
    /* top: 50%;  */
    /* right: 50%; */
    /* transform: translate(50%, -50%);
    text-transform: uppercase;
    
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191,
        1px 2px 1px #919191,
        1px 3px 1px #919191,
        1px 4px 1px #919191,
        1px 5px 1px #919191,
        1px 6px 1px #919191,
        1px 7px 1px #919191,
        1px 8px 1px #919191,
        1px 9px 1px #919191,
        1px 10px 1px #919191,
        1px 18px 6px rgba(16, 16, 16, 0.4),
        1px 22px 10px rgba(16, 16, 16, 0.2),
        1px 25px 35px rgba(16, 16, 16, 0.2),
        1px 30px 60px rgba(16, 16, 16, 0.4); */

}