.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    background-color:  #1b230f;
    height: 90vh;
}
.body>h1{
    text-transform: uppercase;
    
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191,
        1px 2px 1px #919191,
        1px 3px 1px #919191,
        1px 4px 1px #919191,
        1px 5px 1px #919191,
        1px 6px 1px #919191,
        1px 7px 1px #919191,
        1px 8px 1px #919191,
        1px 9px 1px #919191,
        1px 10px 1px #919191,
        1px 18px 6px rgba(16, 16, 16, 0.4),
        1px 22px 10px rgba(16, 16, 16, 0.2),
        1px 25px 35px rgba(16, 16, 16, 0.2),
        1px 30px 60px rgba(16, 16, 16, 0.4);
}

.submit {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
}

.submit>span{
    color: rgb(254, 255, 240);
}

.input {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
}

.input>input {
    width: 15rem;
    padding: 5px;
    border-radius: 5px;
    border-color: orange;
}

.submit-btn {
    width: fit-content;
    padding: 2px 1.4rem;
    background-color: rgb(56, 126, 126);
    border-radius: 8px;
    border-color: rgb(120, 115, 113);
    color: white;

}