.mygoal {
    max-width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1b230f;
}

.mygoal>h1 {
    text-transform: uppercase;

    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191,
        1px 2px 1px #919191,
        1px 3px 1px #919191,
        1px 4px 1px #919191,
        1px 5px 1px #919191,
        1px 6px 1px #919191,
        1px 7px 1px #919191,
        1px 8px 1px #919191,
        1px 9px 1px #919191,
        1px 10px 1px #919191,
        1px 18px 6px rgba(16, 16, 16, 0.4),
        1px 22px 10px rgba(16, 16, 16, 0.2),
        1px 25px 35px rgba(16, 16, 16, 0.2),
        1px 30px 60px rgba(16, 16, 16, 0.4);
}

.body>h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
}

.map {
    display: flex;
    margin: 3rem 0;
    align-items: center;
    column-gap: 1rem;
}

.map>button {
    height: fit-content;
    padding: 0.4rem;
    border-radius: 1rem;
}

table {
    border: 2px solid rgb(215, 222, 215);
    width: 800px;
    height: 200px;
}

th {
    border-bottom: 2px solid rgb(146, 195, 146);
    color: rgb(240, 246, 240);
    font-weight: bolder;
    /* font-size:2px; */
    font-size: larger;
}

td {
    text-align: center;
    color: rgb(232, 242, 232);
    font-size: 20px;
}

.button {
    padding: 5px;
    background-color: rgb(190, 186, 177);
    border-radius: 1rem;
    border-color: orange;
    color: rgb(48, 1, 1);
}

.delete {
    height: 60%;
    width: 60%;
    position: fixed;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #919191;
    z-index: 10;
    top: 20%;
    left: 20%;
    color: rgb(32, 31, 30);
    border-radius: 3rem;
    /* box-shadow: 20px 20px #7e8a0f; */
}

.deletebtn {
    width: 3rem;
    background-color: olivedrab;
    padding: 0.3rem;
    border-radius: 5px;
    border-color: orange;

}

.dlt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
}


  .rec-arrow,  .rec-arrow-right{
    font-size: 20px !important;
    height: 20px !important;
    border-radius: 0px !important;
    line-height: 0 !important;
    min-width: auto !important;

}
.sc-eDvSVe, .lguFiu ,.rec-carousel{
    margin: 0 !important;
    padding: 0 !important;
}

.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    /* padding:30%; */
    left: 45%;
    top: 50%;
    animation: spin 2s linear infinite;
    position: absolute;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}